<template>
  <div class="page1">
    <!--停车记录查询-->
    <div class="content">
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="dialogShow(scope.row)" style="padding: 0"
                >{{ $t('button.edit') }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <!-- <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div> -->
    </div>
    <edit-dialog
      ref="editDiag"
      :id="currentData.id"
      :title="currentData.application"
      :value="value"
    />
  </div>
</template>

<script>
import { setIndex } from "@/common/js/public.js";
import editDialog from "./editDialog.vue";
import buttons from "./buttons.js";
export default {
  name: "mobileHomeManage",
  components: { editDialog },
  data() {
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          // 客户端类型 1 App  3 小程序
          prop: "client",
          label: "客户端",
          width: "",
          formatter: (row, column) => {
            if (row.client == 1) {
              return "App";
            } else if (row.client == 2) {
              return "公众号";
            } else {
              return "小程序";
            }
          },
        },
        {
          prop: "application",
          label: "应用名称",
          width: "",
        },
        {
          prop: "button",
          label: "首页功能区按钮",
          width: "300",
          formatter: (row, column) => {
            var result = "";
            var arr = row.button.split(",");
            for (var value of arr) {
              for (var item of buttons) {
                if (item.key == value) {
                  result += item.label + ",";
                }
              }
            }
            if (result.endsWith(",")) {
              result = result.substring(0, result.length - 1);
            }
            return result;
          },
        },
      ],
      tableData: [],
      currentData: {},
      value: [],
    };
  },
  methods: {
    // 分页
    // handleCurrentChange(val) {
    //   this.pageNum = val;
    //   if (!this.flag) {
    //     this.getAppList();
    //   }
    // },
    // 搜索
    getAppList() {
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/appHomeConfig/queryByList",
        data: {},
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    dialogShow(rowData) {
      this.currentData = rowData;
      this.value = this.currentData.button.split(",");
      this.$refs.editDiag.dialogVisible = true;
    },
  },
  mounted() {
    this.getAppList();
    // this.eventBus.$on('updateWidth', self.updateWidthHandler);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "monitorDetails" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "mask videoDetaiDialog",
          attrs: {
            visible: _vm.dialogVisible,
            center: true,
            top: "10vh",
            title: _vm.title,
            avaliable: true,
            "before-close": _vm.handleClose,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content", staticStyle: { "text-align": "center" } },
            [
              _c("el-transfer", {
                staticStyle: { "text-align": "left", display: "inline-block" },
                attrs: {
                  "render-content": _vm.renderFunc,
                  titles: ["未选择", "已选择"],
                  props: {
                    key: "key",
                    label: "label",
                  },
                  format: {
                    noChecked: "${total}",
                    hasChecked: "${checked}/${total}",
                  },
                  data: _vm.buttons,
                  "target-order": "push",
                },
                model: {
                  value: _vm.data,
                  callback: function ($$v) {
                    _vm.data = $$v
                  },
                  expression: "data",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog(false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog(true)
                    },
                  },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
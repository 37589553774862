var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page1" },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "tableWrapper bgFFF paddingT10 paddingB10" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData },
              },
              [
                _vm._l(_vm.tableCols, function (item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      align: "center",
                      prop: item.prop,
                      label: item.label,
                      width: item.width,
                      formatter: item.formatter,
                      "show-overflow-tooltip": "",
                    },
                  })
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: { padding: "0" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.dialogShow(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.edit")))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("edit-dialog", {
        ref: "editDiag",
        attrs: {
          id: _vm.currentData.id,
          title: _vm.currentData.application,
          value: _vm.value,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const buttons =
  [
    { key: '1', label: "车牌缴费", icon: "replaceOrder.png" },
    { key: '2', label: "意见反馈", icon: "feedback.png" },
    { key: '3', label: "车辆管理", icon: "car.png" },
    { key: '4', label: "常见问题", icon: "faq.png" },
    { key: '5', label: "开具发票", icon: "invoice.png" },
    { key: '6', label: "车位预约", icon: "preserve.png" },
    { key: '7', label: "停车充电", icon: "charge.png" },
    { key: '8', label: "错峰共享", icon: "stagger.png" },
    { key: '9', label: "优惠购卡", icon: "promotion.png" },
  ];

  export default buttons;


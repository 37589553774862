<template>
  <div class="monitorDetails">
    <el-dialog
      :visible.sync="dialogVisible"
      class="mask videoDetaiDialog"
      :center="true"
      top="10vh"
      :title="title"
      :avaliable="true"
      :before-close="handleClose"
      :show-close="false"
    >
      <!-- <div class="imgClose" @click="dialogVisible = false"></div> -->
      <div class="content" style="text-align: center">
        <el-transfer
          style="text-align: left; display: inline-block"
          v-model="data"
          :render-content="renderFunc"
          :titles="['未选择', '已选择']"
          :props="{
            key: 'key',
            label: 'label',
          }"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          :data="buttons"
          target-order="push"
        >
        </el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog(false)">取 消</el-button>
        <el-button type="primary" @click="closeDialog(true)">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import buttons from "./buttons.js";
export default {
  props: ["title", "id", "value"],
  data() {
    return {
      dialogVisible: false,
      buttons: [],
      data: [],
    };
  },
  mounted() {
    this.buttons = buttons;
  },
  watch: {
    value(newVal) {
      this.data = newVal;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    renderFunc(h, option) {
      var path = require("./img/" + option.icon);
      return (
        <span>
          <img src={path} />
          {"  " + option.label}
        </span>
      );
    },
    closeDialog(submit) {
      if (submit) {
        this.updateButton();
      } else {
        this.dialogVisible = false;
      }
    },
    updateButton() {
      this.loading = true;
      let data = {
        id: this.id,
        button: this.data.filter(v => v).join(),
      }
      this.$axios({
        method: "post",
        url: "/acb/2.0/appHomeConfig/modify",
        data: {
          data: JSON.stringify(data),
        },
        headers: {
          "Content-Type": "application/json;",
          "Session-Id": sessionStorage.token || "",
        },
      }).then((res) => {
        this.loading = false;
          if (res.state === 0) {
            this.dialogVisible = false;
            this.$parent.getAppList();
          }
      });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.videoDetaiDialog >>>.el-dialog {
  // width: 664px;
  // height: 358px;
  // -webkit-box-shadow: none;
  // box-shadow: none;
  // margin: 54px auto !important;
}

.videoDetaiDialog >>>.el-dialog__header {
  padding: 18px 0px 0px 0px;
}

.videoDetaiDialog >>>.el-dialog__title {
  font-size: 16px;
}

.videoDetaiDialog .el-dialog--center {
  width: 664px;
}

.videoDetaiDialog {
  display: flex;
  align-items: center;

  >>>.el-dialog--center {
    .el-dialog__body {
      .dialogVideo {
        margin-top: 8px;
      }

      .title {
        text-align: center;
        color: #D6F3FF;
        font-size: 30px;
        margin-top: 12px;
        margin-bottom: 45px;
      }
    }
  }
}
</style>
